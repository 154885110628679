<template>
  <div>
    <v-row>
      <v-col cols="3">
        <v-text-field
          label="Amount(*)"
          placeholder="0"
          type="number"
          hint="* Service fee of 1.25% is applicable, If your daily transaction
              limit exceeds BDT 10000.00"
          persistent-hint
        >
          <template v-slot:message="{ message }">
            <div class="red--text">
              {{message}}
            </div>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="#002441" elevation="0" disabled> Submit </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>